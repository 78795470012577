<template>
  <div class="product-iot">
    <div class="product-iot-head">
      <strong>智能IoT产品</strong>
      <p>实现您的IoT产品<span>国产化</span>升级改造</p>
    </div>
    <div class="product-list-desc">
      <span>通过在物体上植入各种微型感应SOC，借助无线通信网络，与互联网相连接，可以让物体自己“说话”，不仅人可以与物体“对话”，物体与物体之间也能“交流”。鸿联云联创平台致力于为您提供国产化IoT产品，同时通过OpenHarmony适配，实现您的IoT产品国产化升级改造。</span>
    </div>
    <div class="product-iot-box">
      <div class="solution-search-filter">
        <div class="solution-search-tabs">
          <template v-if="isFilter">
            <van-tabs title-active-color="#EA0B06" line-height="0" v-model="solutionTabsIndex" @click="solutionTabs">
              <van-tab v-for="(el,index) in ortOption2" :key="index" :name="el.id" :title="el.name"/>
            </van-tabs>
          </template>
          <p v-else>请选择</p>
        </div>
        <van-dropdown-menu :overlay="false" z-index="20">
          <van-dropdown-item ref="filter" @open="openFilter" @close="closeFilter">
            <template slot="title">
              <span class="solution-search-filter-icon"><van-icon name="play"/></span>
            </template>
            <div class="solution-search-filter-box">
              <div class="solution-search-filter-list">
                <span v-for="(el,index) in ortOption2" :key="el.id" :class="{'active':el.id == categoryId}"
                      @click="solutionFilter(el.id,index)">{{ el.name }}</span>
              </div>
            </div>
          </van-dropdown-item>
        </van-dropdown-menu>
      </div>
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" v-if="iotList.length>0">
        <div class="product-iot-list-box">
          <div class="product-iot-list" v-for="el in iotList" :key="el.id" @click="iosInfo(el.id)">
            <div class="product-iot-list-img">
              <!-- <span><img src="@/assets/img/tjtb.png" alt="推荐"></span> -->
              <v-img :attachment-id="el.coverAttmId " :default-img="require('@/assets/img/kctp.png')"/>
            </div>
            <div class="product-iot-list-info">
              <strong>{{ el.name }}</strong>
              <span>厂商：{{ el.isvName }}</span>
              <p v-if="el.sceneTagName">
                <template v-for="(e,i) in el.sceneTagName.split(',')">
                  <span v-if="i < 2" :key="i">{{ e }}</span>
                </template>
              </p>
            </div>
          </div>
        </div>
      </van-list>

      <van-empty
        v-if="emptybool"
        class="custom-image"
        :image="nonecontent"
        description="暂无内容"
      />

    </div>
    <product-footer/>
    <share :val="shareInfo"/>
  </div>
</template>

<script>
import { getCategoryList } from '@/api/solution'
import { getIotProductList,getProductList } from '@/api/iot'
import VImg from '@/components/VImg'
import productFooter from '../components/productFooter.vue'
import nonecon from '@/assets/img/nonecontent1.png'
import share from '../components/share.vue'

export default {
  components: {
    VImg,
    productFooter,
    share
  },
  data () {
    return {
      nonecontent: nonecon,
      sortVal1: 0,
      sortVal2: 0,
      sortOption1: [
        {
          text: '全部',
          value: 0
        },
      ],
      ortOption2: [
        {
          name: '全部',
          id: ''
        },
      ],
      categoryData: [],
      iotList: [],
      page: {
        current: 1,
        size: 10,
        pageSize: 1,
      },
      categoryId: '',
      loading: false,
      finished: false,
      solutionTabsIndex: 0,
      isFilter: true,
      emptybool: false,
      shareInfo: {
        title: '找｜硬件产品',
        desc: '通过在物体上植入各种微型感应SOC，借助无线通信网络，与互联网相连接，可以让物体自己“说话”，不仅人可以与物体“对话”，物体与物体之间也能“交流”。鸿联云联创平台致力于为您提供国产化IoT产品，同时通过OpenHarmony适配，实现您的IoT产品国产化升级改造。',
        img: `${process.env.VUE_APP_OBS_PREFIX}/1553279520277057537`
      }
    }
  },
  mounted () {
    document.title = '找｜硬件产品'
    if (this.$route.query.id) {
      this.categoryId = this.$route.query.id
    }

    this.loadGetSolutionCategory()
    this.loadGetIotProductList()

    if (this.$route.query.code) {
      this.wechatUtil.saveWechatInfo(this.$route.query.code, localStorage.getItem('openId'))
    }

  },
  methods: {
    onLoad () {
      if (this.iotList == '') {
        return
      }
      if (this.page.pageSize == this.page.current) {
        this.finished = true
        return
      }
      this.page.current++
      this.loading = true
      this.loadGetIotProductList()
    },
    solutionTabs (id, val) {
      this.finished = false
      this.categoryId = id
      this.page.current = 1
      this.iotList = []
      this.loadGetIotProductList()
    },

    solutionFilter (id, index) {
      this.categoryId = id
      this.solutionTabsIndex = id
      this.page.current = 1
      this.iotList = []
      this.$refs.filter.toggle()
      this.loadGetIotProductList()
    },

    // 列表
    loadGetIotProductList () {
      let {
        page,
        categoryId
      } = this
      let data = {
        status: '2',
        categoryId,
        type:'iot',
      }
      getProductList(page, data).then((res) => {
        this.loading = false
        this.iotList = [...this.iotList, ...res.records]
        this.page.pageSize = res.pages
        this.finished = false

        if (this.iotList.length == 0) {
          this.emptybool = true
        } else {
          this.emptybool = false
        }
      })
    },
    // 类别列表
    loadGetSolutionCategory () {
      getCategoryList('1').then((res) => {
        let data = res
        data.forEach((element) => {
          element.value = element.id
          element.text = element.name
          element.children.forEach((item) => {
            item.value = item.id
            item.text = item.name
            this.ortOption2.push(item)
          })
        })
        this.categoryData = data
        this.sortOption1 = [...this.sortOption1, ...data]
        setTimeout(() => {
          this.solutionTabsIndex = this.categoryId
        }, 200)
      })
    },
    // 选择类别
    replaceSort (id) {
      console.log(id)
      let {
        sortOption1,
        categoryData
      } = this
      let index = 0
      this.page.current = 1
      this.iotList = []
      this.ortOption2 = [
        {
          text: '全部',
          value: 0
        },
      ]
      for (let i = 0; i < categoryData.length; i++) {
        if (categoryData[i].id == id) {
          index = i
        }
      }
      if (id != 0) {
        this.categoryId = id
        let data = sortOption1[index + 1].children
        data.forEach((item) => {
          this.ortOption2.push(item)
        })
      } else {
        this.categoryId = ''
        categoryData.forEach((element) => {
          element.children.forEach((item) => {
            this.ortOption2.push(item)
          })
        })
      }
      this.loadGetIotProductList()
    },
    openFilter () {
      this.isFilter = false
    },
    closeFilter () {
      this.isFilter = true
    },
    // 详情
    iosInfo (id) {
      this.$router.push({
        path: '/product/iotInfo',
        query: { id },
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.product-iot {
  min-height: 100%;
  background: #f6f6f6;

  .custom-image {
    ::v-deep {
      .van-empty__image {
        width: 114px;
        height: 74.09px;
      }
    }

    .van-empty__description {
      font-size: 14px;
      font-weight: 500;
      line-height: 46px;
      color: #999999;
    }
  }

  .product-iot-head {
    background: #fff;
    padding: 38px 24px;
    background: url(../../assets/img/5019@2x.png) no-repeat;
    background-size: 100% 100%;
    height: 210px;

    > strong {
      font-size: 20px;
      line-height: 28px;
      color: #333333;
      display: block;
      margin-bottom: 10px;
    }

    > p {
      font-size: 12px;
      line-height: 18px;
      color: #333333;
      margin-bottom: 0;

      > span {
        color: #EA0B06;
      }
    }
  }

  .product-list-desc {
    padding: 0 16px;
    position: relative;
    z-index: 2;
    margin-top: -50px;

    > span {
      display: block;
      padding: 20px 12px;
      font-size: 12px;
      line-height: 18px;
      color: #666666;
      background: url(../../assets/img/5020@2xbg.png) no-repeat;
      background-size: 100% 100%;
      border-radius: 4px;
    }
  }

  .product-iot-box {
    // padding-bottom: 80px;
    .solution-search-filter {
      display: flex;
      height: 48px;
      align-items: center;
      justify-content: space-between;
      margin: 16px;
      background: #fff;

      > p {
        width: calc(100% - 48px);
        margin-bottom: 0;
        overflow-x: auto;
        white-space: nowrap;
        padding: 0 16px;

        &::-webkit-scrollbar {
          display: none;
        }

        > span {
          display: inline-block;
          line-height: 48px;
          font-size: 14px;
          font-weight: 400;
          color: #333;
          margin-right: 20px;

          &:last-child {
            margin-right: 0;
          }

          &.active {
            color: #EA0B06;
          }
        }
      }

      .solution-search-tabs {
        width: calc(100% - 48px);

        > p {
          padding: 0 16px;
          font-size: 14px;
          color: #666;
          line-height: 48px;
        }
      }

      .solution-search-filter-box {
        padding: 16px 36px;

        .solution-search-filter-list {
          display: flex;
          flex-wrap: wrap;

          > span {
            width: 32%;
            line-height: 20px;
            font-size: 14px;
            font-weight: 400;
            color: #333;
            margin-right: 2%;
            margin-bottom: 24px;

            &:nth-child(3n) {
              margin-right: 0;
            }

            &.active {
              color: #EA0B06;
            }
          }
        }

        .solution-search-filter-btn {
          margin-top: 16px;
          display: flex;
          justify-content: space-between;

          > button {
            width: 140px;
            height: 36px;
            line-height: 36px;
          }
        }
      }
    }

    .product-iot-sort {
      padding: 0 16px;
      margin-bottom: 4px;
    }

    .product-iot-list-box {
      padding: 0 16px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .product-iot-list {
        width: 167px;
        background: #FFFFFF;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
        border-radius: 4px;
        margin-bottom: 9px;

        .product-iot-list-img {
          position: relative;
          height: 150px;
          display: flex;
          align-items: center;
          justify-content: center;

          > span {
            position: absolute;
            width: 32px;
            height: 19px;
            left: 10px;
            top: -4px;

            > img {
              display: block;
              width: 100%;
              height: 100%;
            }
          }

          > img {
            display: block;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            width: 90px;
          }
        }

        .product-iot-list-info {
          padding: 8px;

          > strong {
            display: block;
            font-size: 14px;
            font-weight: bold;
            line-height: 20px;
            color: #333333;
            margin-bottom: 4px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          > span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 12px;
            font-weight: 400;
            line-height: 17px;
            color: #666666;
            display: block;
            margin-bottom: 5px;
          }

          > p {
            display: flex;
            margin-bottom: 0;

            > span {
              height: 19px;
              background: #f7f7f7;
              border-radius: 2px;
              padding: 0 6px;
              font-size: 12px;
              font-weight: 400;
              line-height: 19px;
              color: #666;
              padding: 0 6px;
              margin-right: 4px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}
</style>
<style>
.solution-search-filter .van-dropdown-menu__bar {
  background-color: transparent;
  height: auto;
  box-shadow: none;
}

.solution-search-filter .van-dropdown-menu__title {
  padding: 0;
  line-height: 48px;
}

.solution-search-filter .van-dropdown-menu__title::after {
  display: none;
}

.solution-search-filter-icon {
  display: block;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.07);
}

.solution-search-filter .van-dropdown-item__content {
  left: 16px;
  right: 16px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.16);
  width: auto;
}

.solution-search-filter-icon > i {
  transition: all .3s;
  transform: rotate(90deg);
}

.solution-search-filter .van-ellipsis {
  box-shadow: 0px 0px 5px 0 rgba(0, 0, 0, 0.07);
}

.solution-search-filter .van-dropdown-item {
  left: 0;
  right: 0;
}

.van-dropdown-menu__title--active .solution-search-filter-icon > i {
  transform: rotate(270deg);
}
</style>
